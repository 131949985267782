import { Link, useLocation } from "react-router-dom";
import { Button, Dropdown, Layout, Menu, MenuProps } from "antd";
import { useIntl } from "react-intl";
import {
  DashboardOutlined,
  ShopOutlined,
  UserOutlined,
  CreditCardOutlined,
  BookOutlined,
  TeamOutlined,
  ToolOutlined,
  ProductOutlined,
  BankOutlined,
  LineChartOutlined,
  GiftOutlined,
  ClusterOutlined,
  DollarOutlined,
  UserAddOutlined,
  DownOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { GET_EXTERNAL_PROFILE } from "./SideMenu.gql";
import { SET_CURRENT_USER, useAuth } from "../../../hooks/auth/AuthContext";
import "./sidemenu.css";
import rectangle from "../../../assets/images/Rectangle.png";
import { UPDATE_CURRENT_OUTLET } from "../../../pages/login/Login.gql";

const { Sider } = Layout;

export const GET_OUTLET_DROPDOWN = gql`
query OutletDropdown{
    outletDropdown{
        id,
        value
    }
}
`;

interface Props {
  collapsed: boolean;
  setCollapsed: any;
}

type MenuItem = Required<MenuProps>["items"][number];
const SideMenu = ({ collapsed, setCollapsed }: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const [sideNavs, setSideNavs] = useState<any[]>([]);
  const [getExternalProfile, { data }] = useLazyQuery(GET_EXTERNAL_PROFILE, { fetchPolicy: "cache-and-network", });
  const { authState, authDispatch } = useAuth();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const [subMenuKey, setSubMenuKey] = useState<string>();
  const [outletDropdown, setOutletDropdown] = useState<any[]>([]);
  const [activeOutlet, setActiveOutlet] = useState<string | null>(null);
  const [getOutletDropdown] = useLazyQuery(GET_OUTLET_DROPDOWN, {
    onCompleted: (data) => {
      setOutletDropdown(data.outletDropdown);
    }
  });
  const [updateItem] = useMutation(UPDATE_CURRENT_OUTLET);

  useEffect(() => {
    if (authState.isLogin) {
      getExternalProfile();
      getOutletDropdown();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLogin]);

  useEffect(() => {
    // const selectedKeys = location.pathname.split('/');
    setSelectedKey(location.pathname);
    // const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
    // setSubMenuKey(expandedSubMenu);
  }, [location.pathname]);

  useEffect(() => {
    if (data && data.userProfile) {
      const extProfile = data.userProfile;
      authDispatch({
        type: SET_CURRENT_USER,
        payload: {
          userProfile: {
            id: extProfile.id,
            name: extProfile.name,
            userName: extProfile.userName,
            roleName: extProfile.roleName,
            canManageSystemUser: extProfile.canManageSystemUser,
            branches: extProfile.branches,
            userType: extProfile.userType,
            tenantId: extProfile.tenantId,
            profileUrl: extProfile.profileUrl,
            currentOperatingOutlet: extProfile.currentOperatingOutlet,
            email: extProfile.email
          },
          sideNav: extProfile.sideNavigation,
        },
      });
      setActiveOutlet(extProfile.currentOperatingOutlet);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (authState.sideNav && authState.sideNav.length > 0) {
      const sortedData = authState.sideNav;
      const parentSideNavs: any[] = sortedData.filter((sn) => !sn.parentId);
      const nestedSideNav: any[] = parentSideNavs.map((sn) => {
        const parentSideNav = { ...sn };
        parentSideNav.child = sortedData.filter((c) => c.parentId === sn.id);
        return parentSideNav;
      });
      setSideNavs(nestedSideNav);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.sideNav]);

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "UserOutlined":
        return <UserOutlined />;
      case "ShopOutlined":
        return <ShopOutlined />;
      case "CreditCardOutlined":
        return <CreditCardOutlined />;
      case "BookOutlined":
        return <BookOutlined />;
      case "DashboardOutlined":
        return <DashboardOutlined />;
      case "TeamOutlined":
        return <TeamOutlined />;
      case "ToolOutlined":
        return <ToolOutlined />;
      case "ProductOutlined":
        return <ProductOutlined />;
      case "BankOutlined":
        return <BankOutlined />;
      case "LineChartOutlined":
        return <LineChartOutlined />;
      case "GiftOutlined":
        return <GiftOutlined />;
      case "ClusterOutlined":
        return <ClusterOutlined />
      case "DollarOutlined":
        return <DollarOutlined />
      case "UserAddOutlined":
        return <UserAddOutlined />
      case "BarChartOutlined":
        return <BarChartOutlined />
    }
  };

  const renderGroupedMenu = () => {
    const groupedSideNavs = sideNavs.reduce((acc: any, curr: any) => {
      const groupName = curr.groupName || "default";
      if (!acc[groupName]) acc[groupName] = [];
      acc[groupName].push(curr);
      return acc;
    }, {});

    return Object.entries(groupedSideNavs).map(([groupName, items]: any) => (
      <Menu.ItemGroup
        key={groupName}
        title={
            <span>
            {collapsed && groupName === 'CONFIGURATIONS' ? <hr /> : intl.formatMessage({ id: groupName })}
            </span>
        }
      >
        {items.map((s: any) =>
          s.child && s.child.length > 0 ? (
            <Menu.SubMenu
              key={s.id}
              icon={getIcon(s.icon)}
              className="subnmenu"
              title={intl.formatMessage({ id: s.name })}
            >
              {s.child.map((child: any) => (
                <Menu.Item key={child.route}>
                  <Link to={child.route} onClick={() => setSelectedKey(child.route)}>
                    {intl.formatMessage({ id: child.name })}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={s.route} icon={getIcon(s.icon)}>
              <Link to={s.route}>{intl.formatMessage({ id: s.name })}</Link>
            </Menu.Item>
          )
        )}
      </Menu.ItemGroup>
    ));
  };

  const onFinish = (fieldsValue: any) => {
    if (fieldsValue.outletId) {
      updateItem({
        variables: {
          outletId: fieldsValue.outletId,
          userName: authState?.userProfile?.userName
        }
      }).then(() => {
        setActiveOutlet(fieldsValue.outletId); // Update the active outlet
        window.location.reload();
      });
    }
  };

  return (
    <Sider
      className={`overflow-y-scroll !bg-neutral-50 ${collapsed ? "px-2 me-1" : "px-4"}`}
      style={{ scrollbarWidth: "none" }}
      width={window.innerWidth < 768 ? window.innerWidth : 256}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={window.innerWidth < 768 ? 0 : 80}
    >
      <div className="relative flex flex-col gap-2 bg-white rounded-lg py-2 my-2 mb-4">
        <div className="flex items-center justify-around w-full">
          <img
            src={rectangle}
            alt="infinity"
            className={collapsed ? "hidden" : ""}
          />
          <div className={collapsed ? "hidden" : "flex items-center"}>
            <span className="text-xl font-semibold text-indigo-900">
              Infinity&nbsp;
            </span>
            <span className="text-xl font-semibold text-yellow-400">
              Asia
            </span>
          </div>
        </div>
        
        <Dropdown
          className={`${collapsed ? "hidden" : ""}`}
          menu={{ items: outletDropdown.map((outlet) => ({
            key: outlet.id,
            label: outlet.value,
            className: activeOutlet === outlet.id ? "active-outlet" : "",
            onClick: () => onFinish({ outletId: outlet.id }),
          })) }}
          trigger={["hover", "click"]}
          placement="bottomRight"
        >
          <Button icon={<DownOutlined />}>
            {outletDropdown.find((outlet) => outlet.id === activeOutlet)?.value || "Select Outlet"}
          </Button>
        </Dropdown>
      </div>

      <Menu
        selectedKeys={[selectedKey || "/"]}
        // openKeys={subMenuKey ? [subMenuKey] : undefined}
        mode="inline"
        className={collapsed ? "pt-2 rounded-md" : "px-1"}
      >
        {renderGroupedMenu()}
      </Menu>

      {/* <div className={` relative w-full bg-white rounded-lg my-4 px-2 py-5 ${collapsed ? "hidden" : ""} `}>
        <div className="font-semibold text-lg pb-1">
          <FormattedMessage id='UPGRADE_PLAN_1' />
        </div>
        <div className="upgrade-desc">
          <FormattedMessage id='UPGRADE_PLAN_2' />
        </div>
        <Button
          type="primary"
          htmlType="submit"
          icon={<WalletOutlined />}
          className="w-full mt-1 bg-secondaryButton"
        >
          <FormattedMessage id='UPGRADE_PLAN_3' />
        </Button>
      </div> */}
    </Sider>
  );
};

export default SideMenu;
