import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
  path: string;
  component: any;
  routes?: RouteInfo[];
  lazy?: boolean;
  protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
  const Component: any = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const Dashboard = ReactLazyPreload(() => import("./pages/dashboard/Dashboard"));
Dashboard.preload();

const Login = ReactLazyPreload(() => import("./pages/login/Login"));
Login.preload();

const ChangePassword = ReactLazyPreload(() => import("./pages/change-password/ChangePassword"));
ChangePassword.preload();

const Profile = ReactLazyPreload(() => import("./pages/profile/Profile"));
Profile.preload();

const Outlets = ReactLazyPreload(() => import("./pages/outlets/Outlets"));
Outlets.preload();

const OutletUsers = ReactLazyPreload(() => import("./pages/outlet-users/OutlerUsers"));
OutletUsers.preload();

const Role = ReactLazyPreload(() => import("./pages/roles-external/RolesExternal"));
Role.preload();

const TenantUser = ReactLazyPreload(() => import("./pages/tenant-users/TenantUsers"));
TenantUser.preload();

const Customer = ReactLazyPreload(() => import("./pages/customers/Customers"));
Customer.preload();

const Wallets = ReactLazyPreload(() => import("./pages/wallets/Wallets"));
Wallets.preload();

const TenantReferrals = ReactLazyPreload(() => import("./pages/tenant-referrals/TenantReferrals"));
TenantReferrals.preload();

const CreditActivities = ReactLazyPreload(() => import("./pages/reports/credits-activity/CreditsActivity"));
CreditActivities.preload();

const CustomerCards = ReactLazyPreload(() => import("./pages/stamp-card/customers/Customers"));
CustomerCards.preload();

const CardSettings = ReactLazyPreload(() => import("./pages/stamp-card/settings/CardSettings"));
CardSettings.preload();

const ForgotPassword = ReactLazyPreload(() => import("./pages/forgot-password/ForgetPassword"));
ForgotPassword.preload();

const OutletsForm = ReactLazyPreload(() => import("./pages/outlets/components/OutletsForm"));
OutletsForm.preload();

const OutletsDetail = ReactLazyPreload(() => import("./pages/outlets/OutletsDetailModal"));
OutletsDetail.preload();

const RoleForm = ReactLazyPreload(() => import("./pages/roles-external/RolesExternalForm"));
RoleForm.preload();

const RoleDetail = ReactLazyPreload(() => import("./pages/roles-external/RolesExternalDetailModal"));
RoleDetail.preload();

const TenantUserForm = ReactLazyPreload(() => import("./pages/tenant-users/components/TenantUserForm"));
TenantUserForm.preload();

const TenantUserDetail = ReactLazyPreload(() => import("./pages/tenant-users/TenantUsersDetailModal"));
TenantUserDetail.preload();

const WalletsForm = ReactLazyPreload(() => import("./pages/wallets/components/WalletsForm"));
WalletsForm.preload();

const WalletsDetail = ReactLazyPreload(() => import("./pages/wallets/WalletsDetailModal"));
WalletsDetail.preload();

const CustomerForm = ReactLazyPreload(() => import("./pages/customers/components/CustomerForm"));
CustomerForm.preload();

const CustomerDetail = ReactLazyPreload(() => import("./pages/customers/CustomersDetailModal"));
CustomerDetail.preload();

const CreditPackages = ReactLazyPreload(() => import("./pages/credit-package/CreditPackage"));
CreditPackages.preload();

export const routes: RouteInfo[] = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    protected: true,
  },
    {
      path: "/profile/account",
      component: Profile,
      protected: true,
    },
  {
    path: "/profile/change-password",
    component: ChangePassword,
    protected: true,
  },
  {
    path: "/outlets",
    component: Outlets,
    protected: true,
  },
  {
    path: "/outlets/view-outlet/:id",
    component: OutletsDetail,
    protected: true,
  }, 
  {
    path: "/outlets/add-outlet",
    component: OutletsForm,
    protected: true,
  },
  {
    path: "/outlets/edit-outlet/:id",
    component: OutletsForm,
    protected: true,
  },
  {
    path: "/role-permission",
    component: Role,
    protected: true,
  },
  {
    path: "/role-permission/view-role/:id",
    component: RoleDetail,
    protected: true,
  }, 
  {
    path: "/role-permission/add-role",
    component: RoleForm,
    protected: true,
  },
  {
    path: "/role-permission/edit-role/:id",
    component: RoleForm,
    protected: true,
  },
  {
    path: "/users",
    component: TenantUser,
    protected: true,
  },
  {
    path: "/users/view-user/:id",
    component: TenantUserDetail,
    protected: true,
  }, 
  {
    path: "/users/add-user",
    component: TenantUserForm,
    protected: true,
  },
  {
    path: "/users/edit-user/:id",
    component: TenantUserForm,
    protected: true,
  },
  {
    path: "/customers-temp",
    component: Customer,
    protected: true,
  },
  {
    path: "/customers",
    component: Wallets,
    protected: true,
  },
  // {
  //   path: "/customers/view-customer/:id",
  //   component: CustomerDetail,
  //   protected: true,
  // }, 
  // {
  //   path: "/customers/add-customer",
  //   component: CustomerForm,
  //   protected: true,
  // },
  {
    path: "/customers/edit-customer/:id",
    component: CustomerForm,
    protected: true,
  },
  {
    path: "/credit",
    component: CreditPackages,
    protected: true,
  },
  // {
  //   path: "/credit/view-credit/:id",
  //   component: WalletsDetail,
  //   protected: true,
  // }, 
  // {
  //   path: "/credit/add-credit",
  //   component: WalletsForm,
  //   protected: true,
  // },
  {
    path: "/credit/edit-credit/:id",
    component: WalletsForm,
    protected: true,
  },
  {
    path: "/referral",
    component: TenantReferrals,
    protected: true,
  },
  {
    path: "/reports",
    component: CreditActivities,
    protected: true
  },
  // {
  //   path: "/stamp-card/customers",
  //   component: CustomerCards,
  //   protected: true
  // },
  {
    path: "/stamp-card-settings",
    component: CardSettings,
    protected: true
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    protected: false,
  },
  {
    path: "/",
    component: Dashboard,
    protected: true,
  },
  {
    path: "*",
    component: NoMatch,
  },
];
